/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';

const MenuLayer = (props) => {
  return <div>MenuLayer</div>;
};

MenuLayer.propTypes = {};

export default MenuLayer;
